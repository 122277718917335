import {FC, Fragment} from 'react';
import styled from 'styled-components';

import {HoobiizActivityModule} from '@src/components/ui/hoobiiz_activity_module';
import {HoobiizCat1ListView} from '@src/components/ui/hoobiiz_cat1_list_view';
import {useIsHoobiiz} from '@src/lib/hoobiiz_context';

interface HomePageProps {}

export const HomePage: FC<HomePageProps> = () => {
  return (
    <Wrapper>
      <HoobiizCat1ListView />
      {useIsHoobiiz() ? (
        <Fragment>
          <HoobiizActivityModule cse={{mode: 'non-cse-only'}} title="Les dernières offres" />
          <HoobiizActivityModule
            cse={{mode: 'cse-only'}}
            title="Les dernières offres premium"
            hideEmpty
          />
        </Fragment>
      ) : (
        <HoobiizActivityModule
          cse={{mode: 'all', showCheckbox: false}}
          title="Les dernières offres"
        />
      )}
    </Wrapper>
  );
};

HomePage.displayName = 'HomePage';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 32px;
  gap: 48px;
`;
