import {FC, Fragment} from 'react';
import {useLocation} from 'react-router-dom';
import styled from 'styled-components';

import {EmptyFragment} from '@shared-frontend/lib/react';

import {WhiteNavLink} from '@src/components/core/theme';
import {useIsHoobiiz} from '@src/lib/hoobiiz_context';

export const Footer: FC = () => {
  const location = useLocation();
  const isHoobiiz = useIsHoobiiz();

  if (['/404', '/devenir-partenaire', '/youpiiz-cest-quoi'].includes(location.pathname)) {
    return EmptyFragment;
  }

  return (
    <Wrapper>
      <Container>
        {isHoobiiz ? (
          <Fragment>
            <WhiteNavLink to="/devenir-partenaire">Proposez vos activités</WhiteNavLink>
            <WhiteNavLink to="/youpiiz-cest-quoi">Youpiiz c'est quoi ?</WhiteNavLink>
          </Fragment>
        ) : (
          <div>Ce site est proposé par Youpiiz</div>
        )}
        <div>{`Copyright © ${new Date().getFullYear()}`}</div>
        <WhiteNavLink to="/terms">Mentions légales</WhiteNavLink>
      </Container>
    </Wrapper>
  );
};
Footer.displayName = 'PageWrapper';

export const FOOTER_HEIGHT = 50;

const Wrapper = styled.footer`
  background-color: ${p => p.theme.main.accentColor};
  height: ${FOOTER_HEIGHT}px;
  color: ${p => p.theme.main.accentTextColor};
  display: flex;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 32px;
  align-items: center;
`;
