import {FC, useCallback, useLayoutEffect, useRef, useState} from 'react';
import styled, {useTheme} from 'styled-components';

import {HoobiizApi} from '@shared/api/definitions/public_api/hoobiiz_api';
import {HoobiizCat1Id} from '@shared/dynamo_model';

import {NavLink} from '@shared-frontend/components/core/button';
import {SvgIcon} from '@shared-frontend/components/core/svg_icon';
import {EmptyFragment, NULL_REF} from '@shared-frontend/lib/react';
import {useApiCall} from '@shared-frontend/lib/use_api_call';

import {Colors} from '@src/components/core/theme_base';
import {HoobiizCat2View} from '@src/components/ui/hoobiiz_cat2_view';
import {getCat2Url} from '@src/lib/hoobiiz_urls';

const SCROLL_PAGE_SIZE = 0.8;

interface HoobiizCat2ListViewProps {
  cat1Id: HoobiizCat1Id;
}

export const HoobiizCat2ListView: FC<HoobiizCat2ListViewProps> = props => {
  const {cat1Id} = props;
  const theme = useTheme();
  const {data} = useApiCall(HoobiizApi, '/list-cat2', {cat1Id});

  const ref = useRef<HTMLDivElement>(NULL_REF);
  const [showLeftArrow, setShowLeftArrow] = useState<boolean>(false);
  const [showRightArrow, setShowRightArrow] = useState<boolean>(false);

  useLayoutEffect(() => {
    const handler = (): void => {
      if (ref.current) {
        setShowLeftArrow(ref.current.scrollLeft > 0);
        setShowRightArrow(
          ref.current.offsetWidth + ref.current.scrollLeft < ref.current.scrollWidth
        );
      }
    };

    handler();

    const el = ref.current;
    el?.addEventListener('scroll', handler);
    window.addEventListener('resize', handler);

    return () => {
      el?.removeEventListener('scroll', handler);
      window.removeEventListener('resize', handler);
    };
    // Reset when data is fetched
  }, [data]);

  const handleLeftArrowClick = useCallback(() => {
    if (ref.current) {
      ref.current.scrollBy({
        left: -ref.current.offsetWidth * SCROLL_PAGE_SIZE,
        behavior: 'smooth',
      });
    }
  }, []);

  const handleRightArrowClick = useCallback(() => {
    if (ref.current) {
      ref.current.scrollBy({
        left: ref.current.offsetWidth * SCROLL_PAGE_SIZE,
        behavior: 'smooth',
      });
    }
  }, []);

  if (data === undefined) {
    return EmptyFragment;
  }

  return (
    <Wrapper>
      <BackNavLink to="/">
        <SvgIcon name="ChevronLeft" size={11} color={theme.link.textColorActive} />
        Retour
      </BackNavLink>
      <Title>{data.cat1.name}</Title>
      <Description>{data.cat1.description}</Description>
      {data.cat2.length === 0 ? (
        EmptyFragment
      ) : (
        <Gradient>
          {showLeftArrow ? (
            <LeftArrow onClick={handleLeftArrowClick}>
              <SvgIcon name="ChevronLeft" size={20} color={Colors.White} />
            </LeftArrow>
          ) : (
            ''
          )}
          {showRightArrow ? (
            <RightArrow onClick={handleRightArrowClick}>
              <SvgIcon name="ChevronRight" size={20} color={Colors.White} />
            </RightArrow>
          ) : (
            ''
          )}
          <Carousel ref={ref}>
            {data.cat2.map(cat => (
              <HoobiizCat2View
                key={cat.id}
                to={getCat2Url(cat)}
                name={cat.name}
                media={cat.media}
              />
            ))}
          </Carousel>
        </Gradient>
      )}
    </Wrapper>
  );
};

HoobiizCat2ListView.displayName = 'HoobiizCat2ListView';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Gradient = styled.div`
  position: relative;
`;

const LeftArrow = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  z-index: 1;
  cursor: pointer;
  padding: 10px 5px;
  background-color: #00000050;
`;

const RightArrow = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  z-index: 1;
  cursor: pointer;
  padding: 10px 5px;
  background-color: #00000050;
`;

const Carousel = styled.div`
  display: flex;
  height: 100px;
  overflow: hidden;
  gap: 16px;
`;

const Title = styled.div`
  font-size: 30px;
  font-weight: 700;
`;

const Description = styled.div`
  font-size: 15px;
  line-height: 20px;
  color: ${Colors.DarkGrey};
`;

const BackNavLink = styled(NavLink)`
  gap: 8px;
`;
